



























import Vue from "vue";
import { Component } from "vue-property-decorator";
import ItemService from "@/services/items";
import { Item } from "@/services/model/item";
import { UUIDv4 } from "uuid-v4-validator";

@Component
export default class ItemSearch extends Vue {
  search: boolean = false;
  id: string = "";

  async find(id: string) {
    if (UUIDv4.validate(id)) {
      await ItemService.getItem(id)
        .then((item: Item) => {
          console.log(item);
          this.$emit("search-result", Array(item));
        })
        .catch((error) => {
          this.$emit("search-result", Array());
        });
    }
  }

  toggleSearch() {
    this.search = !this.search;
    this.id = "";
  }
}
