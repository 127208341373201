



























import Vue from "vue";
import Component from "vue-class-component";
import { Item } from "@/services/model/item";
import ItemSearch from "@/components/ItemSearch.vue";
import ItemGrid from "@/components/ItemGrid.vue";
import { mapGetters, mapActions } from "vuex";

@Component({
  components: {
    ItemSearch,
    ItemGrid,
  },
  computed: mapGetters("items", ["numberOfPages", "getPage"]),
  methods: mapActions("items", ["loadPage"]),
})
export default class Inventory extends Vue {
  numberOfPages!: number;
  getPage!: (page: number) => Array<Item>;
  loadPage!: (pageNumber: number) => Promise<Object>;
  displayThis: Array<Item> = new Array<Item>();
  page = 1;
  loaded = false;
  menu = false;

  searchEventHandler(items: Array<Item>) {
    this.displayThis = items;
  }

  async paginate() {
    // TODO: when listing a book, add to inventory state.
    // TODO: paginate conditionally - if page is already in state, don't reload.
    await this.loadPage(this.page).then((_) => {
      this.displayThis = [...this.getPage(this.page)];
    });
  }

  mounted() {
    return this.loadPage(1).then((_) => {
      this.displayThis = [...this.getPage(this.page)];
      this.loaded = true;
    });
  }
}
