






































import Vue from "vue";
import Component from "vue-class-component";
import { Item } from "@/services/model/item";
import { mapActions } from "vuex";

const ItemProps = Vue.extend({
  props: {
    items: Array as () => Array<Item>,
  },
});

@Component({
  methods: mapActions("items", ["markSold", "markUnsold"]),
})
export default class ItemGrid extends ItemProps {
  markSold!: (item: Item) => Promise<Object>;
  markUnsold!: (item: Item) => Promise<Object>;

  isSold(item: Item) {
    if (item.soldAt) return true;
    else return false;
  }

  markAsSold(item: Item) {
    this.markSold(item);
    const index = this.items.indexOf(item);
    this.items.splice(index, 1, { ...item, soldAt: "sold" });
  }

  markAsUnsold(item: Item) {
    this.markUnsold(item);
    const index = this.items.indexOf(item);
    this.items.splice(index, 1, { ...item, soldAt: undefined });
  }

  getColor(soldAt?: string) {
    if (soldAt) {
      return "#dbdbdb";
    } else {
      return "#beface";
    }
  }
}
